<template>
    <div>
      <h2>FEUERWIDERSTANDSKLASSE</h2>
      <p>Alle VMM-Decken ab einer Stärke von 24 cm (VMM VSD) bzw. 27 cm (VMM-L EPD und VMM-L SCD) haben immer die Feuerwiderstandsklasse F90. Die 12 und 14 cm starken Platten sind wegen der nötigen Betonüberdeckung nur in F30 lieferbar.<br>Der Nachweis der ausreichenden Querkrafttrag­fähigkeit im Brandfall wird bei der Ausnutzungs­berechnung mit überprüft.</p>
    </div>
</template>

<script>
export default {
  name: "BasicSettingsHelp"
};
</script>

<style lang="scss">
</style>